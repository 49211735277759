import React from 'react';

export const About = () => {
    return(
        <div className="jumbotron">
            <div className="container">
                <h1 className="display-4">React Course App</h1>
                <p className="lead">Version 1.0.0.</p>
            </div>
        </div>
    )
}